<template>
	<div class="tab-wallpapers">
		<div class="wallpaper__cont">
			<div v-for="wpaper, index in form.wallpapers"
			:key="`wallpaper__${index}`" 
			class="wallpaper__item">
				<div class="image">
					<img :src="wpaper.cropped" height=160 width=90 />
				</div>
				<div class="wallpaper_item_tools" 
					v-touch:longtap="() => {showWallpaperTool(index)}"
					v-touch:tap="() => {setWallpaper(index)}">
					<span class="wrapper" span v-if="wpaper.isPressed">
						<div class="tool">
							<span class="iconify" data-icon="uil:edit-alt" data-width="24" data-height="24"></span>
						</div>
						<div class="tool">
							<span class="iconify" data-icon="uil:multiply" data-width="24" data-height="24"></span>
						</div>
					</span>
				</div>
				<div class="selector" v-if="index == currentWallpaper" >
					<span class="iconify" data-icon="uil:check-circle" data-width="24" data-height="24"></span>
				</div>
			</div>
			<div class="add__wallpaper" @click="addWallpaper" >
				<span class="iconify" data-icon="uil:plus" data-width="31" data-height="31"></span>
			</div>
		</div>
		<WallpaperEditor :photoUrl="photoUrl" :isOpen="editorIsOpen" :closer="closeEditor" :wallpapers="form.wallpapers"/>
	</div>
</template>

<script>
import WallpaperEditor from '../../inputs/WallpaperEditor.vue'
import SlotSettingsFormInstance from './formInstance';

export default {
	name: 'TabWallpapers',
	components:{
		WallpaperEditor
	},	
	model:{
		prop: 'form',
		event: 'update:form',	
	},
    props: {
		form: {
			type: SlotSettingsFormInstance,
			required: true,
		}
    },

	data() {
		return {
			currentWallpaper: -1,
			editorIsOpen: false,
			photoUrl: "",
		};
	},

	methods: {

        addWallpaper(){
            let input = document.createElement('input');
            input.type = 'file';

            input.onchange = e => { 
                let file = e.target.files[0];
                console.log('file', file)
                this.$data.photoUrl = URL.createObjectURL(file);
                this.openEditor()
            }

            input.click();
        },

		openEditor(){
            this.editorIsOpen = true
        },

        showWallpaperTool(index){
            this.form.wallpapers[index].isPressed = !this.form.wallpapers[index].isPressed 
        },

        setWallpaper(index){
            if (this.currentWallpaper == index) {
                this.currentWallpaper = -1
            } else {
                this.currentWallpaper = index
            } 
        },
		closeEditor(){   
           	this.editorIsOpen = false
        },
	},
};
</script>

<style scoped>
.wallpaper__cont{
    display: flex;
    height: fit-content;
    flex-wrap: wrap;
    gap: 10px;
}

.wallpaper__item{
    height: 160px;
    width: 90px;
    position: relative;
    color: #0075ff;
}

.wallpaper__item > .selector{
    position: absolute;
    bottom: 7px;
    right: 7px;
    width: fit-content;
}

.wallpaper__item > img{
    pointer-events: none;
}

.wallpaper_item_tools{
    position: absolute;
    top: 0;
    z-index: 4;
    width: inherit;
    height: inherit;
}

.wallpaper_item_tools > .wrapper{
    display: grid;
    grid-template-rows: repeat(2, 50%);
    width: inherit;
    height: inherit;
    user-select: none;
}

.wallpaper_item_tools > .wrapper > .tool{
    display: flex;
    justify-content: center;
    align-items: center;
}

.add__wallpaper{
    border: 3px dashed #9a9aa8;
    color: #9a9aa8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 154px;
    width: 84px;
}
</style>