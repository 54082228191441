<template>
    <div class="slot_settings_form">
        <div class="form">

            <div class="form__main-properties">

                <!-- Template -->
                <div class="form__template" v-if="templates && !disableList.includes('template')">
                    <SlotTemplateSelector
                        v-model="form.template"
                        :templates="templates"
                    />
                </div>

                <!-- Date, time, duration -->

                <div class="form__datetime-duration">
                    <div class="datetime">
                        <DateInput v-model="form.dateString" :disabled="disableList.includes('date')" 
                        :dateFormat="dateFormat" :date="form.date" :form="form" name="date"/>

                        <TimeInput v-model="form.timeString" :disabled="disableList.includes('time')"
                        :form="form" name="time"
                        :max="workTimeInMinutes.end" :min="workTimeInMinutes.start"/>
                    </div>
                    <div class="duration">
                        <div>Duration</div>
                        <BaseInput
                        inputType="number"
                        v-model="form.duration" placeholder="60"
                        :form="form" name="duration"
                        :limits="{
                            min:0,
                            max:1440
                        }"
                        :validator="digitsOnly"
                        :showErrorMessage="false"/>
                    </div>
                    <div class="timezone" v-if="form.timezone">
                        <span @click="() => showTimezoneSelect = true">
                            {{form.timezone.name}}
                        </span>
                    </div>
                    <div class="timezone-difference" v-if="!disableList.includes('timezone-difference')">
                        <div class="current__datetime" v-if="calendarTimezone && calendarTimezone.id != -1 && calendarTimezone.id != form.timezone.id">
                            {{calendarTZdateTime }}
                        </div>
                        <div class="current_week_day" v-else>
                            {{ dateOfWeek }}
                        </div>
                    </div>
                </div>

                <!-- Title -->

                <div class="property">
                    <b-input ref="title" placeholder="Title" v-model="form.title"/>
                </div>

                <!-- Tab bar -->

			    <!-- <TabBar v-model="currentTab" :tabs="tabs"/> -->
            </div>
            
			<b-tabs type="is-toggle" v-model="currentTab" position="is-centered" class="block" expanded>
				<b-tab-item label="Properties">
					<TabProperties      v-show="currentTab == 0" v-model="form"/>
				</b-tab-item>
				<b-tab-item label="Wallpapers">
					<TabWallpapers      v-show="currentTab == 1" v-model="form"/>
				</b-tab-item>
				<b-tab-item :label="`Attenders - ${this.form.fixedAttenders.length || 0}`">
					<TabFixedAttenders  v-show="currentTab == 2" v-model="form.fixedAttenders"/>
				</b-tab-item>
			</b-tabs>	

        </div>
        <TimezoneSelector
            v-model="form.timezone"
            v-show="showTimezoneSelect"
            :show="showTimezoneSelect"
            :closer="() => {showTimezoneSelect = false}"
        />
    </div>
</template>

<script>
import { convertTZ, getStringByFormat } from '../../../scripts/date';
import TabProperties from './TabProperties';
import TabWallpapers from './TabWallpapers'
import TabFixedAttenders from './TabFixedAttenders';

import SlotSettingsFormInstance from './formInstance.js'
import BaseInput from '../../inputs/BaseInput.vue';

const WEEK_DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
]

export default {
    name: 'SlotSettingsForm',
    components: {
        BaseInput,
        TabProperties,
        TabWallpapers,
        TabFixedAttenders,
        TimeInput: () => import('../../inputs/TimeInput.vue'),
        DateInput: () => import('../../inputs/DateInput.vue'),
        SlotColorPicker: () => import('../../inputs/SlotColorPicker.vue'),
        TimezoneSelector: () => import('../../inputs/timezone-selector/TimezoneSelector.vue'),
		SlotTemplateSelector: () => import('../../inputs/slot-template-picker/SlotTemplateSelector.vue'),
    },
	model:{
		prop: 'form',
		event: 'update:form',	
	},
    props: {
        disableList: {
            type: Array,
            default: () => [],
        },
		form: {
			type: SlotSettingsFormInstance,
			required: true,
		}
    },
    watch: {
        'form.date' : {
            handler(newValue){
                if (!newValue) return
                this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)
            }, 
            deep: true,
        },
        'form.timezone' : {
            handler(newValue){
                if (!newValue) return
                this.form.timezone = newValue
                this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)   
            },
            deep: true,
        },
        'form.template' : {
            handler(template){
                this.form.updateByTemplate(template)
            },  
            deep: true,
        },
        'form.duration' : {
            handler(duration){
                if (typeof duration == 'string'){
                    this.form.fields['duration'].setError("duration is invalid")
                }

            },  
            deep: true,
        },
        'form.maxAttenders' : {
            handler(duration){
                if (typeof duration == 'string'){
                    this.form.fields['maxAttenders'].setError("maxAttenders is invalid")
                }
            },  
            deep: true,
        },
        'form.timeString' : {
            handler(newValue) {
                let timeField = this.form.fields["time"]
                if (newValue.length != 5 || timeField.isError) 
                    return
                
                let browserTimezone = this.$store.getters.browserTimezone
                this.form.updateTime(newValue, browserTimezone)
            },
            deep: true,
        },
        'form.dateString' : {
            handler(newValue, oldValue) {

                if (newValue.length != 10) return

                let browserTimezone = this.$store.getters.browserTimezone
                if (oldValue) {
                    let workStart = this.$store.getters.calendarWorkStart
                    let workEnd = this.$store.getters.calendarWorkEnd 
                    this.form.updateDate(newValue, browserTimezone, {
                        start: workStart,
                        end: workEnd,
                    })
                }
                this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)
            },
            deep: true,
        },
    },
    computed: {
 
        timezones(){
            let timezones = this.$store.getters.timezones
            if (!timezones || timezones.length == 0) return []
                
            return timezones
        },

		templates(){
			return this.$store.getters.calendarSlotTemplates
		},

        tabs(){
            let fixedAttenders = this.form.fixedAttenders
            let fixedAttendersCount = fixedAttenders? fixedAttenders.length : 0
            return ["Properties", "Wallpapers", `Attenders - ${fixedAttendersCount}`]
        },

        calendarName(){
            return this.$store.getters.calendarTitle
        },
        calendarTimezone(){
            this.form.calendarTimezone = this.$store.getters.getTimezone
            if (this.form.calendarTimezone.id != -1 && !this.form.timezone){
                let calendarTimezone = this.$store.getters.getTimezone
                let slotTZid = this.form.timezoneId ?? calendarTimezone.id
                this.form.timezone = this.$store.getters.getTimezoneByID(slotTZid)

                if (calendarTimezone.id != this.form.timezone.id){
                    this.form.date = convertTZ(this.form.date, calendarTimezone.name, this.form.timezone.name)
                    this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)
                } 

                this.form.dateFormat = this.dateFormat
                this.form.dateString = getStringByFormat(this.dateFormat.format, this.form.date)
            }
            return this.$store.getters.getTimezone
        },

        dateFormat(){
            return this.$store.getters.dateFormat
        },
        dateOfWeek(){
            return WEEK_DAYS[this.form.date.getDay()]
        },
        workTimeInMinutes(){
            let workStart = this.$store.getters.calendarWorkStart
            let workEnd = this.$store.getters.calendarWorkEnd

            return {
                start : workStart.hour * 60 + workStart.minute,
                end : workEnd.hour * 60 + workEnd.minute,
            }
        },
    },

    mounted() {
        if (this.form.isCreate) {
            setTimeout(() => this.$refs["title"].focus(), 500)
        } else if (!this.form.isCreate && this.form.title == "") {
            this.$refs["title"].focus()
        }
    },

    data() {
        return {
            currentTab: 0,
            
            showTimezoneSelect: false,
            calendarTZdateTime: null,
        };
    },

    methods: {
        calendarDateTimeInTZ(initDate){
            let date = convertTZ(initDate, this.form.timezone.name, this.calendarTimezone.name)

            let calendarTZName = this.calendarTimezone.name.split(' ')[0]
            let result = date.toTimeString().slice(0,5) + ` ${calendarTZName}`

            if (date.getDate() != initDate.getDate()){
                result = date.toLocaleDateString() + " " + result
            }

            this.form.calendarDate = date

            return result
        },
        digitsOnly(value){
            let fixed = value.split('').filter(char => {
                return char != '.' && char != ',' && char != '-'
            }).join('')

            return fixed
        }
    },
};
</script>
<style>
.b-tabs {
    height: calc(100% - 46px);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tabs a {
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.b-tabs .tabs {
    margin: 0 10px;
    width: calc(100% - 20px);
    box-sizing: content-box;
}

.b-tabs .tab-content {
    padding: 10px;
    padding-top: 2px;
    height: calc(100% - 46px);
    overflow: auto;
}
</style>

<style scoped>


.slot_settings_form{
    height: 100%;
}

.form {
    height: calc(100% - 113px);
	display: flex;
	flex-direction: column;
}

.tab-content{
	padding: 0;
}

.form__main-properties{
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px;
    padding-bottom: 0;
}

.form > *:not(.form__main-properties){
    padding-top: 16px;
}

.form > *:not(.form__main-properties) > *{
    overflow-y: auto;
}

nav.tabs{
	position: fixed;
	top: 0;
}


.form__template{
    display: flex;
    justify-content: flex-end;
} 

.form__datetime-duration{
    display: grid;
    grid-template-columns: calc(100% - 16px - 110px) 110px;
    grid-column-gap: 16px;
    grid-row-gap: 3px;
}

.datetime{
    display: flex;
    flex-direction: row;
    gap:10px;
    width: 218px;
}

.duration{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    font-size: 14px;
}

.property{
    width:100%;
}

.timezone{
    color: rgb(0, 117, 255);
    text-decoration: underline;
    font-size: 11px;
    cursor: pointer;
}

.current__datetime{
    text-align: right;
    font-size: 11px;
}

.current_week_day{
    text-align: right;
    font-size: 11px;
}

.custom > input {
    padding-left: 11px;
}

</style>